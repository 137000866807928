<template>
	<div>
		<div class="box">
			<div class="title_box flex" style="justify-content: space-between;">
				<Title title="在线充值" />
				<button class="but_on" @click="TopUpMoney">确认充值</button>
			</div>
			<div class="ct_box">
				<div class="money_box flex">
					<p class="money_title">充值金额:</p>
					<div></div>
					<ul class="money_list flex">
						<li :class="moneyNum == item ? 'active' : ''" v-for="(item, index) in money" :key="index"
							@click="tabShow(item)">
							{{ item }}
						</li>
					</ul>
				</div>
				<div class="text_box flex">
					<el-input @input="change" class="money_text" v-model="moneyNum" placeholder="请输入内容"></el-input>
					<p class="money_p">元，最低充值1元</p>
				</div>
				<div class="zhifubao flex">
					<img @click="check = !check" :src="check
					? require('@/assets/user/squireHover.png')
					: require('@/assets/user/squireNone.png')
					" alt="" class="check" />
					<img src="@/assets/user/zhifubao.png" alt="" class="zhifubao_img" />
				</div>
				<div class="hint">
					提示：若您不方便使用在线充值功能，您可付款到下面账户后通知客服帮您代充值到平台账户
				</div>
				<div class="bot_box flex">
					<div class="flex" style="align-items: center">
						<img src="@/assets/user/yinlian.png" alt="" class="yinhang" />
						<div class="message">
							<p class="message1">
								开户行：中国建设银行股份有限公司杭州萧山行政服务中心支行
							</p>
							<p class="message1">账　号：3305 0161 7091 0000 0211</p>
							<p class="message1">户　名：杭州宇耀网络技术有限公司</p>
						</div>
					</div>
					<div class="flex" style="align-items: center">
						<img src="@/assets/user/zhifubao.png" alt="" class="yinhang2" />
						<div class="message">
							<p class="message1">
								支付宝账号：2656564636@qq.com
							</p>
							<p class="message1">名 称：杭州宇耀网络技术有限公司</p>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import {changeMoney} from '@/util/changeMoney'
import {getUserInfo} from "@/util/getUserInfo";
export default {
	components: {
		Title,
	},
	mounted() {
		this.$store.commit('childTab', '在线充值')
	},
	data() {
		return {
			money: [100, 500, 1000, 2000, 3000, 5000],
			active: 0,
			moneyNum: 100,
			check: false, //选中支付方式
		}
	},
	watch: {
	},

	methods: {
		change(){
			// this.moneyNum = changeMoney(this.moneyNum)
		},
		tabShow(item) {
			this.moneyNum = item
		},
		// 充值
		TopUpMoney() {
			if (this.moneyNum == 0) {
				return this.$message({
					message: '请选择要充值的金额',
					type: 'warning',
				})
			}
			if (!this.check) {
				return this.$message({
					message: '请选择支付方式',
					type: 'warning',
				})
			}
			this.curlPost('/api/users/recharge', {
				style: 1,
				amount: this.moneyNum,
			}).then(res => {
				console.log(res);
				if (res.data.code) {
					const div = document.createElement('divform');
					div.innerHTML = res.data.data; // data就是接口返回的form 表单字符串
					document.body.appendChild(div);
					let len = document.forms.length - 1;
					// document.forms[len].setAttribute('target', '_blank'); // 新开窗口跳转
					document.forms[len].submit();
          getUserInfo();
				}
			})
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/scss/Refill';
</style>